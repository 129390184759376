import getClientIPExperimental from '@joybird/joystagram-components/dist/esm/getClientIPExperimental';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import getEnvValue from '@joybird/joystagram-components/dist/esm/getEnvValue';
import env from '../services/env';
import { get } from 'lodash';
import { isClient } from '../app/globals';
import history from '../history';

const USER_AGENT_FILTER = /instagram|FBAN|FBAV|Pinterest/gi;
const ERROR_FILTER = /QuotaExceeded|Illegal invocation|Failed to fetch|^cancelled$|RequestAbortedError|Network error: cancelled|The network connection was lost|Network Failure|Network Error|NetworkError when attempting to fetch resource|The Internet connection appears to be offline|InvalidSignatureException|wistia|Can't find variable: performance|Undefined variable: performance|undefined is not an object \(evaluating 'Math.floor'\)/gi;

function getKibanaURL(clusterNS) {
  const to = new Date();
  to.setSeconds(to.getSeconds() + 30);

  const from = new Date();
  from.setMinutes(from.getMinutes() - 5);

  return `https://vpc-joybird-xmp5lgoh7fpcwap44r3qjafp7a.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from.toISOString()}',mode:absolute,to:'${to.toISOString()}'))&_a=(columns:!(kubernetes.container_name,kubernetes.namespace_name,log),filters:!((query:(match:(kubernetes.container_name:(query:alexandria,type:phrase)))),(query:(match:(kubernetes.namespace_name:(query:${clusterNS},type:phrase))))),interval:auto,query:(language:kuery,query:'${getClientIPExperimental() ||
    ''}'),sort:!('@timestamp',desc))`;
}

function shouldIgnoreEvent(event) {
  if (USER_AGENT_FILTER.test(get(event, 'request.headers.User-Agent', ''))) {
    return true;
  }

  if (
    ERROR_FILTER.test(get(event, 'exception.values.0.type', '')) ||
    ERROR_FILTER.test(get(event, 'exception.values.0.value', ''))
  ) {
    return true;
  }

  return false;
}

function refreshPage() {
  // Re-assign href to force the page to refresh without POST data
  const currentHref = window.location.href;
  window.location.href = currentHref;

  // Force a standard refresh for browsers that don't support the approach above
  window.location.reload(true);

  console.log('Refreshing page');
}

const clientVersion =
  process.env.NEXT_PUBLIC_PACKAGE_VERSION ??
  getEnvValue('REACT_APP_PACKAGE_VERSION');

function addDataToEvent(event, objKey, data) {
  if (!event[objKey]) {
    event[objKey] = {};
  }

  for (const key of Object.keys(data)) {
    event[objKey][key] = data[key];
  }

  return event;
}

function addTagsToEvent(event, tags) {
  return addDataToEvent(event, 'tags', tags);
}

function addExtrasToEvent(event, extras) {
  return addDataToEvent(event, 'extra', extras);
}

function initSentry() {
  if (env.VERSION !== 'development' && isClient()) {
    let clusterNS = 'dev';

    if (env.VERSION === 'staging') {
      clusterNS = 'stg';
    } else if (env.VERSION === 'production') {
      clusterNS = 'prod';
    }

    const ssrVersion = document.querySelector('meta[name="ssr-version"]')
      ?.content;

    const SENTRY_ORG_ID = 'joybird';

    Sentry.init({
      environment: env.VERSION,
      dsn: env.SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          tracingOrigins: [
            'admin-api.feature.nest.joybird.com',
            'admin-api.dev.nest.joybird.com',
            'admin-api.stg.nest.joybird.com',
            'admin-api.prod.nest.joybird.com',
            'api.feature.nest.joybird.com',
            'api.dev.nest.joybird.com',
            'api.stg.nest.joybird.com',
            'api.prod.nest.joybird.com',
          ],
        }),
      ],
      tracesSampleRate: 0.02,
      release: clientVersion,
      beforeSend: (event, hint) => {
        if (shouldIgnoreEvent(event)) {
          console.log('Ignoring an error event', event);
          return null;
        }

        event = addExtrasToEvent(event, { kibanaUrl: getKibanaURL(clusterNS) });

        event = addTagsToEvent(event, {
          URL: document.location.href,
          SSR_VERSION: ssrVersion,
          CLIENT_VERSION: clientVersion,
        });

        if (
          get(event, 'exception.values.0.type', '').indexOf('QuotaExceeded') >
            -1 ||
          get(event, 'exception.values.0.value', '').indexOf('QuotaExceeded') >
            -1
        ) {
          const localStorageString = JSON.stringify(window.localStorage);
          addTagsToEvent(event, {
            localStorageLength: localStorageString.length,
            localStorage: localStorageString,
          });
        }

        /* CON-1479 .Send primitive types to heap */
        if (
          ['Error', 'ChunkLoadError'].includes(
            get(event, 'exception.values.0.type', '')
          ) &&
          /Loading (CSS )?chunk .* failed/.test(
            get(event, 'exception.values.0.value', '')
          )
        ) {
          refreshPage();

          const chunkName = get(event, 'exception.values.0.value', '').match(
            /http.*(js|css)/
          );

          console.error(
            `CHUNK_LOAD_DELAYED_ERROR - ${chunkName && chunkName[0]}`
          );

          return null;
        }

        if (document.location.href.indexOf('/checkout') === -1) {
          return event;
        }

        return addTagsToEvent(event, {
          'JB4.0-CRITICAL': 'true',
          ERRORTYPE: 'CHECKOUT_ERROR',
        });
      },
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],
    });
  }
}

export default initSentry;
